import { Component, OnInit, Inject } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/service/alert.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss']
})
export class ImageCropComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropWidth = 10;
  cropHeight = 10;
  scale = 1;
  canvasRotation = 0;
  transform: ImageTransform = {};

  fileChangeEvent(event: any): void {

    if (event.target.files && event.target.files[0]) {
      const max_size = 2000000;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 0;
      const max_width = 0;


      if (!_.includes(allowed_types, event.target.files[0].type)) {
        this.alert.openAlert('Only Images are allowded', "close");

      } else if (event.target.files[0].size > max_size) {
        this.alert.openAlert('Maximum size allowed is 2Mb', "close");
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (e) => {
          const img_height = e.currentTarget['height'];
          const img_width = e.currentTarget['width'];
          if (img_height > max_height && img_width > max_width) {
            this.alert.openAlert('Maximum dimentions allowed ' + 'max_height' + '*' + max_width + 'px', "close");
          } else {
            this.imageChangedEvent = event;
          }

        }
      }
    }
  }
  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  imageCropped(event: ImageCroppedEvent) {

    this.croppedImage = event.base64

  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  uploadImage() {
    let image = this.dataURLtoFile(this.croppedImage, "luke.png");
    this.dialogRef.close(image);
  }

  constructor(
    private alert: AlertService, private dialogRef: MatDialogRef<ImageCropComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {


  }

  ngOnInit() {
    this.cropWidth = this.data.cropWidth;
    this.cropHeight = this.data.cropHeight;
  }
  cancel() {
    this.dialogRef.close();
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
}
