export const environment = {
  production: false,
  // base_url_identity: "http://192.168.1.210:20310/identityframe/api/110",
  // base_url_system_admin: "http://192.168.1.210:21310/systemadminframe/api/110",
  // base_url_support_admin: "http://192.168.1.210:21710/supportframe/api/110",
  // base_url_enterprise:"http://192.168.1.210:26110/enterpriseframe/api/110",

  marketplaceKey: "c092a08fdcAAC",
  // marketplaceKey: "b74e67bfc1AFO",
  base_url_identity: "https://enterprise.skartio.app/enterpriseframe/api/110",
  base_url_system_admin: "https://skartioadmin.skartio.app/systemadminframe/api/110",
  base_url_support_admin: "https://supportadmin.skartio.app/supportframe/api/110",
  base_url_enterprise: "https://enterprise.skartio.app/enterpriseframe/api/110",
  
  image_dimension: {
    icon: { width: 50, height: 50 },
    thumbnail: { width: 150, height: 150 },
    image: { width: 300, height: 300 }
  },
  // logout_url: "https://www.skartio.com",
  logout_url: "http://localhost:4200/#/landing",
  client_url: "http://localhost:4200",
  authenticate_url: "http://onboarding.skartio.lan/#/landing/account?source=SUPPORT",
  initial_page: "dashboard/support/home",
  platform_type: "SUPPORT",
  platform_token: "c2hhc3RoYXNrYXJ0aW9wbGF0Zm9ybQ==",
  platform_salt: "SKARTIO"
};
