import { Injectable } from '@angular/core';
@Injectable()
export class CustomValidators {


    public numberCharcterCheck(charCode) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(charCode);

        if (!pattern.test(inputChar)) {
            return true;
        } else {
            return false;
        }
    }

      public decimalCharcterCheck(charCode) {
        const pattern = /[0-9\.]/;
        let inputChar = String.fromCharCode(charCode);

        if (!pattern.test(inputChar)) {
            return true;
        } else {
            return false;
        }
    }

    public specialCharcterCheck(charCode) {
        const pattern = /^[A-Z0-9\/\\\d\_\s]+$/i;
        let inputChar = String.fromCharCode(charCode);

        if (!pattern.test(inputChar)) {
            return true;
        } else {
            return false;
        }
    }

}