import { UserService } from 'src/app/service/user.service';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private userService: UserService
    ) { }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // httpRequest = httpRequest.clone({
        //     setHeaders: {
        //         Authorization: `Bearer ` + this.userService.getUserToken()
        //     }
        // });
        return next.handle(httpRequest).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status == 401) {
                    this.userService.removeUserLoginDetails();
                    this.router.navigate(["index/signin"]);
                }
                return throwError(error);
            })
        );
    }
}