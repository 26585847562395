import { FormControl, ControlContainer, FormGroupDirective } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-username-input',
  templateUrl: './username-input.component.html',
  styleUrls: ['./username-input.component.scss']
})
export class UsernameInputComponent implements OnInit {
  @Input() formFieldName: FormControl;
  @Output() newItemEvent = new EventEmitter<string>();
  username = "";

  countryCode = [
    { name: '+91 India', id: '+91' },
    { name: '+1 USA', id: '+1' },
  ];
  codeValue = "";
  isNumeric = false;

  constructor() { }

  ngOnInit() {
  }

  onKey(event: any) { // without type info
    this.checkNumeric(event.target.value);
    this.newItemEvent.emit(event);
  }

  checkNumeric(n) {
    this.isNumeric = !isNaN(parseFloat(n)) && isFinite(n);
    return this.isNumeric;
  }
}
