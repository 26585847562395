import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
@Injectable()
export class UserService {
    public userToken = environment.platform_token;
    public userName = 'Guest';
    public userLoginStatus = false;
    public platformSalt = environment.platform_salt;
    public userEmail = '';
    public userMobile = ''
    public isAuthenticated = false;
    public userData = {
        name: '',
        token: environment.platform_token,
        loginFlag: false,
        cartItemCount: 0,
        storeUrl: ''
    }
    jwtHelper = null;
    constructor() {
       
        let token = JSON.parse(localStorage.getItem("suserData"));
        if (token) {
            this.isAuthenticated = true;
            this.userToken = token.token;
        }else{
        }
    }
    private userDataObserver = new BehaviorSubject(this.userData);
    public getUserToken() {
        return this.userToken;
    }
    public getIsAuthenticated() {
        return this.isAuthenticated;
    }
    public getPlatformSalt() {
        return this.platformSalt;
    }
    public getUserLoginStatus() {
        return this.userLoginStatus;
    }
    public setUserDataStorage(content: Object) {
        localStorage.setItem('suserData', JSON.stringify(content));
    }
    public getUserDataDecoded(){
      let token = JSON.parse(localStorage.getItem("suserData"));
      if (token) {
        return   jwt_decode(token.token)
      }else{
        return null;
      }
    }
    public getUserDataStorage() {
        return JSON.parse(localStorage.getItem("suserData"));
    }
    public getUserData() {
        return this.userDataObserver;
    }
    public removeUserToken() {
        this.userToken = environment.platform_token;
        localStorage.setItem('suserData', null);
        this.isAuthenticated = false;
    }
    public setUserToken(token) {
        this.userToken = token.token;
        localStorage.setItem('suserData', JSON.stringify(token));
        this.isAuthenticated = true;
    }

    public removeUserLoginDetails(){
        this.userToken = environment.platform_token;
        localStorage.setItem('suserData', null);
        this.isAuthenticated = false;
    }
    
    public getIdentityToken(){}
    public getAccessKey(){
        return(null);
    }
}