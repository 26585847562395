import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LeftNavService, NavItem } from 'src/app/service/left-nav.service';

@Component({
  selector: 'app-menu-list-horizontal',
  templateUrl: './menu-list-horizontal.component.html',
  styleUrls: ['./menu-list-horizontal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListHorizontalComponent implements OnInit {

  expanded: boolean;
  selectedModule = "";

  @HostBinding('attr.aria-expanded') ariaExpanded;
  @Input() item: NavItem;
  @Input() depth: number;

  constructor(
    public router: Router,
    private _leftNavService: LeftNavService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this._leftNavService.mainMenuSelected$.subscribe(res => {
      this.selectedModule = res;
    });
  }

  onItemSelected(item: NavItem) {
    this._leftNavService.mainMenuSelected$.next(item.displayName)
    this.router.navigate([item.route]);
  }

  checkActive(item: NavItem) {
    return item.displayName == this.selectedModule ? true : false;
  }
}
