import { ImageCropComponent } from './../components/custom/image-crop/image-crop.component';
import { UsernameInputComponent } from './../components/custom/username-input/username-input.component';
import { PasswordStrengthBarComponent } from './../components/custom/password-strength-bar/password-strength-bar.component';
import { InlineTextComponent } from './../components/custom/inline-text/inline-text.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//Angular Material Components
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';;
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ClickOutsideModule } from 'ng-click-outside';
import { MenuListComponent } from '../components/custom/menu-list/menu-list.component';
import { MenuListHorizontalComponent } from '../components/custom/menu-list-horizontal/menu-list-horizontal.component';

@NgModule({
  declarations: [
    InlineTextComponent,
    PasswordStrengthBarComponent,
    UsernameInputComponent,
    ImageCropComponent,
    MenuListComponent,
    MenuListHorizontalComponent
  ],
  imports: [
    CommonModule,
    ClickOutsideModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressButtonsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatDividerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatTreeModule,
    // NgxMatSelectSearchModule
    CKEditorModule,
    ImageCropperModule
  ],
  exports: [
    MatProgressSpinnerModule,
    CKEditorModule,
    ImageCropperModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatDividerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatTreeModule,


    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    // NgxMatSelectSearchModule

    InlineTextComponent,
    PasswordStrengthBarComponent,
    UsernameInputComponent,
    ImageCropComponent,
    MenuListComponent,
    MenuListHorizontalComponent
  ]



})
export class SharedModule { }
