import { TokenInterceptor } from './auth/token.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';

import { routing } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { LeftNavService } from './service/left-nav.service';
import { TopicObservableService } from './service/topic-observable.service';
import { ConfirmDialogComponent } from './components/custom/confirm-dialog/confirm-dialog.component';
import { UploaderService } from './service/uploader.service';
import { APP_BASE_HREF, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CustomValidators } from './service/custom-validator.service';
import { UserService } from 'src/app/service/user.service';
import { LoaderService } from './service/loader.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgMatSearchBarModule } from 'ng-mat-search-bar';
import { ConfirmDialogWithVerificationComponent } from './components/custom/confirm-dialog-with-verification/confirm-dialog-with-verification.component';
import { HttpClientSupportAdminService } from './service/http-client-support-admin.service';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AlertService } from 'src/app/service/alert.service';
import { LandingHomeComponent } from './components/landing-home/landing-home.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};



@NgModule({
  declarations: [
    AppComponent, ConfirmDialogComponent, ConfirmDialogWithVerificationComponent, LandingHomeComponent

  ],
  imports: [
    PerfectScrollbarModule,
    BrowserModule,
    routing,
    NgMatSearchBarModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],

  entryComponents: [ConfirmDialogComponent, ConfirmDialogWithVerificationComponent],
  providers: [LeftNavService, TopicObservableService, UploaderService, CustomValidators,
    HttpClientSupportAdminService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AlertService,
    UserService, LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
