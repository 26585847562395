import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LeftNavService, NavItem } from 'src/app/service/left-nav.service';
import { iconAnimation, labelAnimation, sidebarAnimation } from 'src/app/animations/animations';
import { SidebarService } from 'src/app/service/sidebar.service';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ]),
    sidebarAnimation(),
    iconAnimation(),
    labelAnimation(),
  ]
})
export class MenuListComponent implements OnInit {
  sidebarState: string;

  expanded: boolean = false;
  clicked: boolean = false;
  @Input() item: NavItem;
  @Input() depth: number;
  @Output() onNavigated = new EventEmitter<any>();
  mainMenu: NavItem[] = [];

  constructor(
    public router: Router,
    private _leftNavService: LeftNavService,
    private sidebarService: SidebarService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
    this.mainMenu = this._leftNavService.getMainMenu;
  }
  active = false;
  ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (this.item.route && this.router.isActive(this.item.route, false)) {
          let parentMenuIndex = this._leftNavService.getParent(this.router.url);
          this._leftNavService.mainMenuSelected$.next(this.mainMenu[parentMenuIndex].displayName);
          setTimeout(() => {
            this.active = true;
          }, 1);
        } else {
          setTimeout(() => {
            this.active = false;
          }, 1);
        }
      }
    });
    this.checkActive(this.item);

    this.sidebarService.sidebarStateObservable$.
      subscribe((newState: string) => {
        this.expanded = false;
        this.sidebarState = newState;
      });
  }

  onItemSelected(item: NavItem) {
    this.clicked = true;
    if (!item.children || !item.children.length) {
      if (item.route.indexOf("https") >= 0) {
        this.onNavigated.emit(true);
        window.open(item.route, "_blank");
      } else {
        if (item.route) {
          this.onNavigated.emit(true);
          this.router.navigate([item.route]);
        }
      }
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
  checkActive(item: NavItem) {
    this.active = false;
    if (item.children && item.children.length) {
      item.children.forEach(element => {
        if (!this.active) {
          this.active = element.route ? this.router.isActive(element.route, false) : false;
        }
      });
      if (!this.clicked) {
        this.expanded = this.active;
      }
      return this.active;
    } else {
      this.active = item.route ? this.router.isActive(item.route, false) : false;
      return this.active;
    }
  }

  navigationSuccess() {
    if (this.sidebarState == 'close') {
      this.expanded = false;
    }
    setTimeout(() => {
      this.checkActive(this.item);
    }, 100)
  }

  buttonEnter() {
    if (this.depth == 0 && this.sidebarState == 'close' && this.item.children && this.item.children.length > 0) {
      this.onItemSelected(this.item);
    }
  }

  buttonLeave() {
    if (this.depth == 0 && this.sidebarState == 'close') {
      this.expanded = false;
    }
  }
}
