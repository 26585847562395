import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { TopicObservableService } from './service/topic-observable.service';
import { LoaderService } from './service/loader.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public subTitle = "";
  public navFlag = false;

  constructor(private router: Router, private topicService: TopicObservableService,
    private loader: LoaderService) {

    this.loader.consumeLoader().subscribe(data => {
      console.log(data);
      setTimeout(() => {
        this.navFlag = data;
      });
      // asyncFunctionCall().then(res => {

      // })


    })

    router.events.subscribe((val) => {
      // see also
      // console.log(val instanceof NavigationEnd)
      if (val instanceof NavigationEnd) {
        this.navFlag = false;
      }
      if (val instanceof NavigationError) {
        this.navFlag = false;
      }
      if (val instanceof NavigationStart) {
        this.navFlag = true;
      }

    });
  }

  ngOnInit() {

    this.topicService.getTopic("product-title").subscribe(data => {
      console.log(data);
      this.subTitle = data;
    })
  }


  homePage() {
    this.router.navigate(['home']);
  }

}
