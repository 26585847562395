import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-inline-text',
  templateUrl: './inline-text.component.html',
  styleUrls: ['./inline-text.component.scss']
})
export class InlineTextComponent implements OnInit {

  @Input()
  get value(): string { return this._value; }
  set value(x: string) {
    this.initialValue = this.currentValue = this._value = x;
  }
  @Input() needInline: boolean = true;

  @Output() onFinalized = new EventEmitter<any>();
  @Output() onSemiFinalized = new EventEmitter<any>();
  @Output() markDirty = new EventEmitter<any>();



  private _value = '';
  initialValue = '';
  currentValue = '';

  constructor() { }

  ngOnInit() {
  }

  callFinalized() {
    this.onFinalized.emit(this.currentValue);
  }

  changeValue(evt) {
    if (!this.needInline) {
      this.onSemiFinalized.emit(evt);
      this.markDirty.emit();
    }
  }

}
