import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
  private observableLoader:any= null;
  private observableSpinnerLoader:any=null;
    constructor(){
      this.observableLoader= new BehaviorSubject<any>(false);
      this.observableSpinnerLoader= new BehaviorSubject<any>(false);
    }

    public consumeLoader(){
      return this.observableLoader;
    }

    public consumeSpinnerLoader(){
      return this.observableSpinnerLoader;
    }

    public setSpinnerLoaderState(data: boolean) {

      this.observableSpinnerLoader.next(data);

    }

    public setLoaderState(data: boolean) {

      this.observableLoader.next(data);

    }

}
