import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs'

@Injectable()
export class TopicObservableService {


  private observableTopics:any= {};

  constructor(){
    this.observableTopics['product-title'] = new BehaviorSubject<any>({});
    this.observableTopics['product-data'] = new BehaviorSubject<any>({});
    this.observableTopics['product-dashboard-title-data'] = new BehaviorSubject<any>({});
    this.observableTopics['inventory-title'] = new BehaviorSubject<any>({});
    this.observableTopics['inventory-data'] = new BehaviorSubject<any>({});
    this.observableTopics['selected-module'] = new BehaviorSubject<any>({});
    this.observableTopics['selected-sub-module'] = new BehaviorSubject<any>({});
    this.observableTopics['selected-button'] = new BehaviorSubject<any>({});
    this.observableTopics['inventory-selected-warehouse'] = new BehaviorSubject<any>({});
    this.observableTopics['inventory-selected-marketplace'] = new BehaviorSubject<any>({});
    this.observableTopics['skartio-title'] = new BehaviorSubject<any>({});
    this.observableTopics['user-details'] = new BehaviorSubject<any>({});
    this.observableTopics['view-user'] = new BehaviorSubject<any>({});
  }


  public setTopic(topicName){
    console.log(topicName);
   this.observableTopics[topicName] = new BehaviorSubject<any>({});
  }


  public getTopic(topicName){
    return this.observableTopics[topicName];
  }

  public emitData(topicName,data){
      console.log(topicName);
      this.observableTopics[topicName].next(data);
  }
}