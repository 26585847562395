import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/service/alert.service';

@Component({
  selector: 'app-confirm-dialog-with-verification',
  templateUrl: './confirm-dialog-with-verification.component.html',
  styleUrls: ['./confirm-dialog-with-verification.component.scss']
})
export class ConfirmDialogWithVerificationComponent implements OnInit {
  title: string;
  message: string;
  placeholder: string;

  typedValue;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogWithVerificationComponent>,
    private alert: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogWithVerificationComponentModel
  ) {
    this.title = data.title;
    this.message = data.message;
    this.placeholder = data.placeholder;
  }

  ngOnInit() {
  }

  onConfirm() {
    if (this.typedValue == this.data.compareValue) {
      this.dialogRef.close(true);
    } else {
      this.alert.openAlert("Value mismatch", 'dismiss');
    }
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
export class ConfirmDialogWithVerificationComponentModel {

  constructor(
    public title: string,
    public message: string,
    public compareValue: string,
    public placeholder: string
  ) {
  }
}