import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { LandingHomeComponent } from './components/landing-home/landing-home.component';


export const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', component: LandingHomeComponent },
  { path: 'dashboard', loadChildren: () => import('src/app/components/dashboard/dashboard.module').then(m => m.DashboardModule) }
];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes,{ useHash: true, relativeLinkResolution: 'legacy' });
