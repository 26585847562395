import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/service/user.service';


@Injectable({
  providedIn: 'root'
})
export class HttpClientSupportAdminService {

  public baseUrl: string = ""
  public baseAuthUrl: string = ""
  public marketplaceToken: string = ""

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    // this.baseUrl = "http://192.168.1.210:21700/supportframe/api/110";
    this.baseUrl = environment.base_url_support_admin;
    this.baseAuthUrl = environment.base_url_enterprise;
    this.marketplaceToken = environment.marketplaceKey;
  }

  getService(url: string, actionTag: string): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + this.userService.getUserToken(),
        "actiontag": actionTag,
        "responseFlag": "on"
      }
    );
    return this.http.get<any>(this.baseUrl + url, { headers: headers });
  }

  postWithOutTokenService(url: string, params: object): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post<any>(this.baseUrl + url, JSON.stringify(params), { headers: headers });
  }

  postService(url: string, params: object, actionTag: string): Observable<any> {
    console.log(actionTag)
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + this.userService.getUserToken(),
        "actiontag": actionTag,
        "responseFlag": "on"
      }
    );
    return this.http.post<any>(this.baseUrl + url, JSON.stringify(params), { headers: headers });
  }

  putService(url: string, params: object, actionTag: string): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + this.userService.getUserToken(),
        "actiontag": actionTag,
        "responseFlag": "on"
      }
    );
    return this.http.put<any>(this.baseUrl + url, params, { headers: headers });
  }

  patchService(url: string, params: object, actionTag: string): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + this.userService.getUserToken(),
        "actiontag": actionTag,
        "responseFlag": "on"
      }
    );
    return this.http.patch<any>(this.baseUrl + url, params, { headers: headers });
  }

  deleteService(url: string, actionTag: string): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + this.userService.getUserToken(),
        "actiontag": actionTag,
        "responseFlag": "on"
      }
    );
    return this.http.delete<any>(this.baseUrl + url, { headers: headers });
  }

  deleteServiceWithBody(url: string, params: object, actionTag: string): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + this.userService.getUserToken(),
        "actiontag": actionTag,
        "responseFlag": "on"
      }
    );
    const options = {
      headers: headers,
      body: params,
    };

    return this.http.delete<any>(this.baseUrl + url, options);
  }


  postImageService(url: string, params: FormData, actionTag: string): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Authorization': 'Bearer ' + this.userService.getUserToken(),
        "actiontag": actionTag,
        "responseFlag": "on"
      }
    );
    return this.http.post<any>(this.baseUrl + url, params, { headers: headers })
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  login(url: string, userName: string, password:string, actionTag: string) {

    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer '+ environment.platform_token,
        'platformSalt': environment.platform_salt,
        "actiontag": actionTag,
        "responseFlag": "on"
      }
    );
    let data ={
      "pathParams": {},
      "queryParams": {},
      "data": {
          "account": {
              "username": userName,
              "password": password,
              "identityType": "EMAIL",
              "service": "SUPPORT",
              "serviceType": "SUPPORT"
            },
          "enterpriseToken": this.marketplaceToken
        }
    }
    return this.http.post<any>(this.baseAuthUrl + url, data, { headers: headers });
}
}

