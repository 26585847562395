import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs'

@Injectable()
export class LeftNavService {

  // With this subject you can save the sidenav state and consumed later into other pages.
  public leftNavState$: Subject<boolean> = new Subject();
  public mainMenuSelected$: BehaviorSubject<string> = new BehaviorSubject("");

  constructor() { }

  private mainMenu: NavItem[] = [
    {
      id: '',
      displayName: 'Support',
      allowedLevels: ["1", "5"],
      route: '/dashboard/product/home',
      children: [
        {
          id: 'dashboard',
          displayName: 'Dashboard',
          allowedLevels: ["1", "5"],
          iconName: 'dashboard',
          route: '/dashboard/support/home'
        },
        {
          id: 'team',
          displayName: 'Team',
          allowedLevels: ["1"],
          iconName: 'groups',
          route: '/dashboard/support/team'

        },
        {
          id: 'team',
          displayName: 'Members',
          allowedLevels: ["1"],
          iconName: 'group_add',
          route: '/dashboard/support/members'
        },
        {
          id: 'caseCreate',
          displayName: 'Create case',
          allowedLevels: ["1"],
          iconName: 'create',
          route: '/dashboard/support/caseCreate'

        },
        {
          id: 'caseType',
          displayName: 'Type',
          allowedLevels: ["1"],
          iconName: 'create',
          route: '/dashboard/support/caseType'

        },
        {
          id: 'caseCategory',
          displayName: 'Category',
          allowedLevels: ["1"],
          iconName: 'category',
          route: '/dashboard/support/caseCategory'
        },
        {
          id: 'caseQueue',
          displayName: 'Queue',
          allowedLevels: ["1"],
          iconName: 'queue',
          route: '/dashboard/support/caseQueue'
        },
        {
          id: 'pendingCase',
          displayName: 'Unassigned Cases',
          allowedLevels: ["1"],
          iconName: 'pending',
          route: '/dashboard/support/pendingCase'
        },
        {
          id: 'pendingCase',
          displayName: 'Queues',
          allowedLevels: ["5"],
          iconName: 'pending',
          route: '/dashboard/support/pendingCase'
        },
        {
          id: 'acceptedCase',
          displayName: 'Assigned Cases',
          allowedLevels: ["1", "5"],
          iconName: 'accessible',
          route: '/dashboard/support/acceptedCase'
        },
        {
          id: 'closedCase',
          displayName: 'Closed Case',
          allowedLevels: ["1", "5"],
          iconName: 'close',
          route: '/dashboard/support/closedCase'
        },
        {
          id: 'subscription',
          displayName: 'Subscription',
          allowedLevels: ["1"],
          iconName: 'subscriptions',
          route: '/dashboard/support/subscription'
        },
      ]
    }
  ];

  public get getMainMenu(): NavItem[] {
    return this.mainMenu;
  }

  public getSubMenu(displayName): NavItem[] {
    let selectSubMenu = this.mainMenu.filter(res => {
      return res.displayName == displayName;
    });
    return selectSubMenu.length > 0 ? selectSubMenu[0].children : [];
  }

  private searchArrayIndex = 0;
  private searchFound = false;
  public getParent(url) {
    this.searchArrayIndex = 0;
    this.searchFound = false;
    let menuIndex = this.searchMenu(this.mainMenu, url, false) - 1;
    return menuIndex < 0 ? 0 : menuIndex;
  }

  private searchMenu(menu: NavItem[], url, childLevel) {
    menu.forEach(item => {
      if (this.searchFound) {
        return true;
      } else {
        if (!childLevel) {
          this.searchArrayIndex++;
        }
        if (item.children && item.children.length) {
          this.searchMenu(item.children, url, true);
        } else {
          if (item.route == url) {
            this.searchFound = true;
            return true;
          }
        }
      }
    });
    return this.searchFound ? this.searchArrayIndex : 0;
  }

}

export interface NavItem {
  displayName: string;
  allowedLevels: any[];
  id: string;
  disabled?: boolean;
  iconName?: string;
  route?: string;
  badge?: string;
  children?: NavItem[];
}