import { DOCUMENT } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { Input, Component, Output, EventEmitter, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClientSupportAdminService } from 'src/app/service/http-client-support-admin.service';
import { UserService } from 'src/app/service/user.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { environment } from 'src/environments/environment';
import { style, animate, trigger, state, transition, AnimationBuilder, AnimationPlayer } from "@angular/animations";
@Component({
  selector: 'app-landing-home',
  templateUrl: './landing-home.component.html',
  styleUrls: ['./landing-home.component.scss'],
  animations: [
    trigger("fadeAnimation", [
      transition("false=>true", [
        style({ opacity: 0 }),
        animate("500ms", style({ opacity: 1 }))
      ]),
      //when we write '500ms  100ms' means that the animation spend 500ms, and start afer 100ms
      transition("true=>false", [animate("500ms 1000ms", style({ opacity: 0 }))])
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class LandingHomeComponent implements OnInit {
  @ViewChild("wordCarousel", { static: false }) wordCarouselEl: ElementRef;
  wordCarousel = ["Easy", "Flexible", "Scalable", "Agile"];
  wordCounter = -1;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private httpClientSupportAdmin: HttpClientSupportAdminService,
    @Inject(DOCUMENT) private document: Document,

  ) { }

  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  ngOnInit(): void {
    // window.addEventListener('storage', (event) => {
    //   debugger;
    //   if (event.storageArea == localStorage) {
    //     debugger;
    let token = localStorage.getItem('userData');
    let tokenmain = localStorage.getItem('main');
    if (token == undefined) {
      if (tokenmain != undefined) {
        this.router.navigate(['/index/signin'], { queryParams: { main: "1", logout: "1" } });
        //  this.document.location.href = this.callbackURL + "?access_token=" + encodeURIComponent(tokenmain) 
      } else {
        this.router.navigate(['/landing']);
      }
    } else {
      this.router.navigate(['/checking']);
    }
    //   }
    // }, false);

  }

  toogle: boolean = true;
  ngAfterViewInit() {
    setTimeout(() => {
      this.toogle = false;
    })
  }
  nextWord(event: any) {
    this.toogle = !this.toogle;
    if (event.fromState)
      this.wordCounter = (this.wordCounter + 1) % this.wordCarousel.length;
  }


  // siginIn() {
  //   this.document.location.href = environment.authenticate_url + "&call_back=" + encodeURIComponent(environment.client_url + "#/index/signin");
  // }
  OnClear() {
    this.form.reset();
  }

  siginIn() {
    const encodedPassword = btoa(this.form.value.password);
    this.httpClientSupportAdmin.login('/skartioAppsAccount', this.form.value.username,encodedPassword, 'siginInEnterpriseApps').subscribe(result => {
      if (result.status == 200) {
        console.log(result)
        let decodeData= jwt_decode(result.data.token)
        decodeData["token"]=result.data.token
        this.userService.setUserToken(decodeData);
        this.router.navigate(['/dashboard']);
      } else {
        console.log('error');
      }
    });
    this.OnClear();
  }



}

